<template>
  <div>
    <v-btn color="primary" depressed class="mb-5" @click="kembali()">
      <v-icon left>mdi-arrow-left</v-icon>
      Kembali
    </v-btn>

    <v-row>
      <v-col cols="12" md="7">
        <v-card class="px-5 py-2">
          <div class="mt-2 mb-3">
            <v-row no-gutters>
              <div class="mr-5">
                <v-avatar color="primary" size="30">
                  <v-icon dark> mdi-account-circle </v-icon>
                </v-avatar>
                <span class="font-weight-medium ml-2 my-auto">{{
                  pengaduan.pengaduan_pengadu
                }}</span>
              </div>

              <div class="text--secondary my-auto mr-5">
                <v-icon small>mdi-tag-multiple-outline</v-icon>
                <span class="text-caption ml-1 my-auto">{{
                  pengaduan.jp_nama
                }}</span>
              </div>

              <div class="text--secondary my-auto">
                <v-icon small>mdi-calendar</v-icon>
                <span class="text-caption ml-1 my-auto">{{
                  tglFormat(pengaduan.pengaduan_created_at)
                }}</span>
              </div>
            </v-row>
          </div>

          <div class="mb-3">
            <span>No. Tiket : </span
            ><span class="font-weight-black">{{
              pengaduan.pengaduan_no_tiket
            }}</span>
          </div>

          <h2 class="primary--text mb-3">{{ pengaduan.pengaduan_judul }}</h2>

          <div class="mb-5">{{ pengaduan.pengaduan_isi }}</div>
        </v-card>
      </v-col>

      <v-col cols="12" md="5">
        <v-card class="py-2">
          <v-card-title>
            <span>STATUS PENGADUAN</span>
          </v-card-title>

          <v-card-text>
            <v-timeline dense>
              <div v-for="(item, i) in trackingData" :key="i">
                <v-timeline-item
                  :color="item.dp_status === 'selesai' ? 'success' : 'grey'"
                  small
                  fill-dot
                >
                  <v-alert
                    :color="item.dp_status === 'selesai' ? 'success' : 'grey'"
                    class="white--text"
                  >
                    <div>
                      <v-icon color="white" small class="mr-2"
                        >mdi-clock-time-nine-outline</v-icon
                      >
                      <span class="caption">
                        {{ tglFormat(item.dp_created_at) }}
                      </span>
                    </div>
                    <div class="mt-2">
                      {{ item.dp_ket }}
                    </div>
                  </v-alert>
                </v-timeline-item>
              </div>
            </v-timeline>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import refreshView from "@/store/pengaduanForm/viewForm";

export default {
  name: "Pengaduan",

  computed: {
    refresh: {
      get() {
        return refreshView.state.Refresh;
      },
    },
  },

  watch: {
    refresh() {
      this.getData();
    },

    pengaduan() {
      this.tracking();
    },
  },

  data: () => ({
    pengaduan: {},
    trackingData: [],
  }),

  mounted() {
    this.getData();
  },

  methods: {
    getData() {
      this.http
        .get(
          process.env.VUE_APP_API_BASE +
            "pengaduan/public/detail/" +
            this.$route.params.id
        )
        .then((res) => {
          this.pengaduan = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    tracking() {
      this.http
        .get(
          process.env.VUE_APP_API_BASE + "tindaklanjut?noTiket=" + this.pengaduan.pengaduan_no_tiket
        )
        .then((res) => {
          this.trackingData = res.data.data;
          this.alertGagal = false;
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
          this.alertGagal = true;
        });
    },

    tglFormat(tgl) {
      const date = new Date(tgl);
      const dateTimeFormat = new Intl.DateTimeFormat("id", {
        year: "numeric",
        month: "long",
        day: "2-digit",
      });
      const formatted = dateTimeFormat.formatToParts(date);
      return (
        formatted[0].value + " " + formatted[2].value + " " + formatted[4].value
      );
    },

    kembali() {
      this.$router.push("/pengaduan/beranda/").catch(() => {});
    },
  },
};
</script>
